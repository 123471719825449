<template>
  <div ref="content" class="content grid" :class="{ transition, expanded }">
    <div class="wrapper">
      <div class="inner">
        <FolderLink
          v-for="(project, index) in section"
          :key="index"
          :directory="directory"
          :folder="folder"
          :index="index"
          :image="project.image"
        >
          <div>{{ project.title }}</div>
        </FolderLink>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef, no-unused-vars, vue/no-unused-components */
import { mapGetters } from "vuex";
import FolderLink from "@/components/FolderLink.vue";

export default {
  props: ["directory", "folder", "isActive"],
  components: {
    FolderLink,
  },
  data() {
    return {
      transition: false,
      expanded: false,
    };
  },
  computed: {
    ...mapGetters(["getExhibitsById", "transitionEvent"]),
    section() {
      return this.getExhibitsById(this.directory, this.folder);
    },
  },
  watch: {
    isActive(val) {
      this.transition = true;
      this.expanded = val;
      this.transitionEvent &&
        this.$refs.content.addEventListener(
          this.transitionEvent,
          this.transitionContentEnd
        );
    },
  },
  methods: {
    transitionContentEnd() {
      this.transition = false;
      this.$refs.content.removeEventListener(
        this.transitionEvent,
        this.transitionContentEnd
      );
    },
  },
  mounted() {
    if (this.isActive) this.expanded = true;
  },
};
</script>

<style>
.directory .grid {
  --grid-columns: 2;
  --grid-rows: 1;
  --grid-cell-height: calc((100 * var(--vh, 1vh) - 19.2rem) / var(--grid-rows));
  --grid-cell-width: calc(100% / var(--grid-columns));
}

.directory .grid .wrapper {
  width: calc(100% + 1px);
  background-image: linear-gradient(
      to right,
      var(--primary-color) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, var(--primary-color) 1px, transparent 1px);
  background-repeat: repeat;
  background-position: -1px -1px;
  background-size: var(--grid-cell-width) var(--grid-cell-height);
}

.directory .grid .inner {
  overflow: auto;
  grid-template-columns: repeat(var(--grid-columns), var(--grid-cell-width));
  display: grid;
}

@media only screen and (max-width: 374px) and (min-height: 432px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (max-width: 374px) and (min-height: 593px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (max-width: 374px) and (min-height: 753px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (max-width: 374px) and (min-height: 913px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) and (min-height: 474px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) and (min-height: 661px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) and (min-height: 849px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) and (min-height: 1036px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 414px) and (max-width: 479px) and (min-height: 503px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 414px) and (max-width: 479px) and (min-height: 710px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 414px) and (max-width: 479px) and (min-height: 917px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 414px) and (max-width: 479px) and (min-height: 1124px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 480px) and (max-width: 639px) and (min-height: 552px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 480px) and (max-width: 639px) and (min-height: 793px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 480px) and (max-width: 639px) and (min-height: 1032px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 480px) and (max-width: 639px) and (min-height: 1273px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 640px) {
  .directory .grid {
    --grid-columns: 3;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) and (min-height: 513px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) and (min-height: 726px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) and (min-height: 939px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) and (min-height: 1153px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) and (min-height: 1366px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 769px) {
  .directory .grid {
    --grid-columns: 2;
  }
}

@media only screen and (min-width: 769px) {
  .directory .grid {
    --grid-cell-height: calc(
      (100 * var(--vh, 1vh) - 9.6rem) / var(--grid-rows)
    );
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 347px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 514px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 680px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 847px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 1013px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 769px) and (max-width: 833px) and (min-height: 1180px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 371px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 554px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 736px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 919px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 1101px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 834px) and (max-width: 905px) and (min-height: 1284px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 398px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 599px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 799px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 1000px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 1200px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 906px) and (max-width: 1023px) and (min-height: 1401px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 442px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 673px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 903px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 1133px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 1362px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) and (min-height: 1593px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1152px) {
  .directory .grid {
    --grid-columns: 3;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 359px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 534px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 709px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 884px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 1058px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1279px) and (min-height: 1233px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 391px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 588px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 784px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 980px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 1176px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) and (min-height: 1371px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 441px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 654px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 877px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 1099px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 1322px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) and (min-height: 1545px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 472px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 721px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 970px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 1220px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 1469px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) and (min-height: 1718px) {
  .directory .grid {
    --grid-rows: 7;
  }
}

@media only screen and (min-width: 1920px) {
  .directory .grid {
    --grid-columns: 4;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 438px) {
  .directory .grid {
    --grid-rows: 2;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 665px) {
  .directory .grid {
    --grid-rows: 3;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 892px) {
  .directory .grid {
    --grid-rows: 4;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 1119px) {
  .directory .grid {
    --grid-rows: 5;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 1346px) {
  .directory .grid {
    --grid-rows: 6;
  }
}

@media only screen and (min-width: 1920px) and (min-height: 1573px) {
  .directory .grid {
    --grid-rows: 7;
  }
}
</style>
